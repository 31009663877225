import { useState, useEffect } from "react";

/**
 * External imports
 */
import { toInteger, uniq } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useForm,
  useTabs,
  useSelector,
  useEvents,
  useParams,
  useActions,
  useLocation,
  useUserUtils,
  useTranslation,
  useProductUtils,
  useWorkOrderUtils,
  useTyreDimensionsForm,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FormBody, WorkOrderRouteParams } from "./Context";
import {
  Product,
  WorkOrder,
  LoyaltyCard,
  SelectOption,
  BreadcrumbPath,
} from "../../types";
import {
  RequestOnError,
  UpdateWorkOrderBody,
  GetWorkOrderOnSuccess,
  UpdateWorkOrderOnSuccess,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const EditWorkOrderProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the socket event listener
   */
  const { listen } = useEvents();

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the view mode
   */
  const { viewMode } = useSelector((state) => state.workOrder);

  /**
   * Gets the route params
   */
  const { workOrderId } = useParams<WorkOrderRouteParams>();

  /**
   * Stores the work order in edit mode
   */
  const [workOrder, setWorkOrder] = useState<WorkOrder>();

  /**
   * Initializes the breadcrumb paths
   */
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbPath[]>([]);

  /**
   * Initializes the edit loading flag
   */
  const [editLoading, setEditLoading] = useState(false);

  /**
   * Initializes the loading flag
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the products list
   */
  const [productsList, setProductsList] = useState<SelectOption[]>([]);

  /**
   * Initializes the services list
   */
  const [servicesList, setServicesList] = useState<SelectOption[]>([]);

  /**
   * Initializes the work order not found flag
   */
  const [workOrderNotFound, setWorkOrderNotFound] = useState(false);

  /**
   * Initializes the work order removed flag
   */
  const [workOrderRemoved, setWorkOrderRemoved] = useState(false);

  /**
   * Initializes the work order products
   */
  const [workOrderProducts, setWorkOrderProducts] = useState<Product[]>([]);

  /**
   * Initializes the data prefilled flag
   * The InputSearchWorkOrder keeps searchings when re-rendering due to the products/services tab changing the state in the context, this flag will prevent that
   */
  const [dataPrefilled, setDataPrefilled] = useState(false);

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the query params
   */
  const { search } = useLocation();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage, updateWorkOrder, removeWorkOrder, updateViewMode } =
    useActions();

  /**
   * Gets work order utils
   */
  const {
    getErrorTabs,
    getDefaultValues,
    getWorkOrderBody,
    createBreadcrumb,
    getWorkOrderById,
    checkTabForErrors,
    shouldValidateTab,
    shouldInvalidateTab,
    getDefaultTabsState,
    updateBreadcrumbViewMode,
    convertWorkOrderToInputs,
  } = useWorkOrderUtils();

  /**
   * Initializes the tabs state
   */
  const {
    activeTab,
    errorTabs,
    disabledTabs,
    validatedTabs,
    activateTab,
    setActiveTab,
    setErrorTabs,
    setDisabledTabs,
    setValidatedTabs,
  } = useTabs({ defaults: getDefaultTabsState });

  /**
   * Gets products utils
   */
  const { getSelectOptions } = useProductUtils();

  /**
   * Gets user utils
   */
  const { getUserProducts, isUserWorker, getClientById } = useUserUtils();

  /**
   * Gets the user products
   */
  const userProducts = getUserProducts;

  /**
   * Initializes the form
   */
  const methods = useForm<FormBody>({
    defaultValues: getDefaultValues(),
    mode: "all",
    criteriaMode: "all",
  });

  /**
   * Gets the form methods
   */
  const {
    reset,
    setValue,
    getValues,
    handleSubmit,
    control,
    watch,
    formState,
  } = methods;

  /**
   * Gets the tyre dimensions state
   */
  const {
    tyreDimensions,
    addNewDimensionsRow,
    removeDimensionsRow,
    replaceDimensionsRows,
  } = useTyreDimensionsForm({ control, watch });

  /**
   * Handles initializing the view mode
   */
  const initializeViewMode = (searchParams: string) => {
    const query = new URLSearchParams(searchParams);

    if (isUserWorker && query.get("viewMode") === "edit") {
      return updateViewMode("edit");
    } else {
      updateViewMode(query.get("viewMode") as "view" | "edit");
    }
  };

  /**
   * Handles initializing the breadcrumb
   */
  const initializeBreadcrumb = () => {
    const baseBreadcrumb = createBreadcrumb("edit");
    const viewMode = getInitialViewMode();
    const breadcrumb = updateBreadcrumbViewMode(viewMode, baseBreadcrumb, 1);

    setBreadcrumbPaths(breadcrumb);
  };

  /**
   * Handles changing the view mode
   */
  const changeViewMode = (value: "edit" | "view") => {
    updateViewMode(value);
    setBreadcrumbPaths(updateBreadcrumbViewMode(value, breadcrumbPaths));
  };

  /**
   * Handles the tab change
   */
  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    validateTab(activeTab);
    activateTab(event, newValue);
  };

  /**
   * Handles the form submission
   */
  const onFormSubmit = handleSubmit((data: FormBody) => {
    if (userProducts.length < 1) {
      setActiveTab(2);
      return dispatchMessage({
        message: t("StillFetchingProductsPleaseTryAgain"),
        severity: "warning",
      });
    }

    if (editLoading) return;

    /**
     * Handles updating a work order
     */
    setEditLoading(true);
    saveWorkOrder(data);
  });

  /**
   * Handles validating a tab
   */
  const validateTab = (index: number) => {
    const formBody = getValues();
    const shouldValidate = shouldValidateTab(index, formState, formBody);
    const shouldInvalidate = shouldInvalidateTab(index, formState, formBody);
    const hasErrors = checkTabForErrors(index, formState);

    if (shouldValidate) {
      setValidatedTabs((prevTabs) => uniq([...prevTabs, index]));
    }

    if (shouldInvalidate) {
      setValidatedTabs(validatedTabs.filter((tab) => tab !== index));
    }

    if (errorTabs.includes(index) && !hasErrors) {
      setErrorTabs(errorTabs.filter((tab) => tab !== index));
    }
  };

  /**
   * Handles the scan result
   */
  const handleScanResult = (card: LoyaltyCard) => {
    const client = getClientById(card.organizationClientId);

    if (client) {
      setValue("workOrder.clientName", client.name);
    }
  };

  /**
   * Handles deleting the work order
   */
  const deleteWorkOrder = () => {
    setWorkOrderRemoved(true);
    setWorkOrderNotFound(false);
  };

  /**
   * Handles initializing the work order products
   */
  const initializeWorkOrderProducts = (
    products: string[],
    userProducts: Product[],
  ) => {
    setWorkOrderProducts(
      userProducts.filter((product) => products.includes(product.name)),
    );
  };

  /**
   * Returns the initial view mode
   */
  const getInitialViewMode = () => {
    if (!search) return "edit";
    const query = new URLSearchParams(search);

    return query.get("viewMode") as "view" | "edit";
  };

  /**
   * Handles initializing the work order related state
   */
  const initializeWorkOrder = (workOrder: WorkOrder) => {
    const viewMode = getInitialViewMode();
    const formBody = convertWorkOrderToInputs(workOrder);
    const baseBreadcrumb = createBreadcrumb("edit", workOrder);
    const breadcrumb = updateBreadcrumbViewMode(viewMode, baseBreadcrumb);

    reset(formBody);
    setErrorTabs([]);
    setDisabledTabs([]);
    setWorkOrder(workOrder);
    updateWorkOrder(workOrder);
    setBreadcrumbPaths(breadcrumb);
    setValidatedTabs([0, 1, 2, 3]);
    setValue("products", formBody.products);
  };

  /**
   * Handles requesting the work order
   */
  const requestWorkOrder = async () => {
    setLoading(true);

    /**
     * Defines the api call success callback
     */
    const onSuccess: GetWorkOrderOnSuccess = ({ data }) => {
      setLoading(false);
      initializeWorkOrder(data);
    };

    /**
     * Defines the api call error callback
     */
    const onError: RequestOnError = (error) => {
      setLoading(false);
      if (error.errorMessage.includes("No query results")) {
        setWorkOrderRemoved(false);
        setWorkOrderNotFound(true);
        return;
      } else {
        dispatchMessage({
          message: "Error: Requesting work order failed.",
          severity: "error",
        });
      }
    };

    await apiCalls.getWorkOrder(workOrderId, onSuccess, onError);
  };

  /**
   * Handles updating the work order
   */
  const mutateWorkOrder = async (
    data: UpdateWorkOrderBody,
    callback: (data: WorkOrder) => void,
    onError: () => void,
  ) => {
    if (workOrder) {
      /**
       * Handles the success of the api call
       */
      const onSuccess: UpdateWorkOrderOnSuccess = ({ data }) => {
        updateWorkOrder(data);
        setWorkOrder(data);
        callback(data);
      };

      /**
       * Handles the request error
       */
      const handleError: RequestOnError = () => {
        onError();
        dispatchMessage({
          message: "Error",
          severity: "error",
        });
      };

      /**
       * Handles getting the daily work orders
       */
      await apiCalls.updateWorkOrder(
        workOrder.id,
        data,
        onSuccess,
        handleError,
      );
    }
  };

  /**
   * Handles saving the work order
   */
  const saveWorkOrder = async (formBody: FormBody) => {
    if (workOrder) {
      const { id } = workOrder;

      /**
       * Gets the api calls
       */
      const { updateWorkOrderRelations } = apiCalls;

      /**
       * Updates the work order
       */
      const onWorkOrderUpdate: UpdateWorkOrderOnSuccess = ({ data }) => {
        setEditLoading(false);
        setWorkOrder(data);
        dispatchMessage({
          title: data.uuid,
          message: t("WorkOrderUpdated"),
          severity: "success",
          autoClose: 5000,
        });
      };

      /**
       * Handles the request error
       */
      const handleError: RequestOnError = () => {
        setEditLoading(false);
        dispatchMessage({
          message: "Error: Failed to update work order.",
          severity: "error",
        });
      };

      /**
       * Updates the work order data
       */
      await updateWorkOrderRelations(
        id,
        getWorkOrderBody(formBody),
        onWorkOrderUpdate,
        handleError,
      );
    }
  };

  /**
   * Gets the initial services and products
   */
  useEffect(() => {
    if (userProducts.length > 0) {
      const { services, products } = getSelectOptions(userProducts);

      setServicesList(services);
      setProductsList(products);
    }
    // eslint-disable-next-line
  }, [userProducts]);

  /**
   * Handles initializing the work order products
   */
  useEffect(() => {
    if (workOrder && workOrder.products && userProducts.length > 0) {
      const productNames = workOrder.products.map((product) => product.name);
      initializeWorkOrderProducts(productNames, userProducts);
    }
    // eslint-disable-next-line
  }, [userProducts, workOrder]);

  /**
   * Checks the tabs for errors when the active tab changes
   */
  useEffect(() => {
    setErrorTabs(getErrorTabs(formState));
    // eslint-disable-next-line
  }, [activeTab]);

  /**
   * Enabled or disables the summary tab
   */
  useEffect(() => {
    setDisabledTabs(validatedTabs.length > 2 ? [] : [3]);
    // eslint-disable-next-line
  }, [validatedTabs]);

  /**
   * Initializes the breadcrumb path
   */
  useEffect(() => {
    initializeBreadcrumb();
    // eslint-disable-next-line
  }, []);

  /**
   * Initializes the view mode
   */
  useEffect(() => {
    if (search) initializeViewMode(search);
    // eslint-disable-next-line
  }, [search]);

  /**
   * Handles requesting the work order in edit mode
   */
  useEffect(() => {
    if (userInitialized) {
      requestWorkOrder();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles deleting the work order through web sockets
   */
  useEffect(() => {
    if (userInitialized) {
      listen({
        channel: "work-order",
        event: "WorkOrder",
        withAuth: true,
        callback: (payload) => {
          const { event, model, modelName } = payload;

          if (modelName === "WorkOrder" && event === "DELETED") {
            if (model.id === toInteger(workOrderId)) {
              deleteWorkOrder();
            }
          }
        },
      });
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  useEffect(() => {
    if (isUserWorker && viewMode === "edit") {
      updateViewMode("view");
    }
  }, [isUserWorker, viewMode]);

  useEffect(() => {
    return () => {
      removeWorkOrder();
    };
  }, []);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    methods,
    loading,
    viewMode,
    workOrder,
    activeTab,
    errorTabs,
    workOrderId,
    editLoading,
    validateTab,
    productsList,
    servicesList,
    setErrorTabs,
    disabledTabs,
    setActiveTab,
    setWorkOrder,
    onFormSubmit,
    validatedTabs,
    tyreDimensions,
    changeViewMode,
    mutateWorkOrder,
    breadcrumbPaths,
    deleteWorkOrder,
    handleTabChange,
    handleScanResult,
    setValidatedTabs,
    workOrderRemoved,
    workOrderProducts,
    workOrderNotFound,
    addNewDimensionsRow,
    removeDimensionsRow,
    replaceDimensionsRows,
    dataPrefilled,
    setDataPrefilled,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
