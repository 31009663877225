import { useState, useEffect } from "react";

/**
 * External imports
 */
import { toInteger, uniq } from "lodash";

/**
 * Imports hooks
 */
import {
  useApi,
  useForm,
  useTabs,
  useSelector,
  useEvents,
  useParams,
  useHistory,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useProductsForm,
  useProductUtils,
  useWorkOrderUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import {
  FormBody,
  UpdateDraftProps,
  DraftWorkOrderRouteParams,
} from "./Context";
import { SelectOption, BreadcrumbPath, WorkOrder } from "../../types";
import {
  RequestOnError,
  GetDraftWorkOrderOnSuccess,
  UpdateWorkOrderDraftOnSuccess,
  TransformWorkOrderDraftOnSuccess,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const DraftWorkOrderProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the socket event listener
   */
  const { listen } = useEvents();

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Initializes the start date
   */
  const [startDate] = useState(new Date());

  /**
   * Initializes the draft work order
   */
  const [draftWorkOrder, setDraftWorkOrder] = useState<WorkOrder>();

  /**
   * Initializes the loading flag
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the draft loading flag
   */
  const [draftLoading, setDraftLoading] = useState(false);

  /**
   * Initializes the products list
   */
  const [productsList, setProductsList] = useState<SelectOption[]>([]);

  /**
   * Initializes the services list
   */
  const [servicesList, setServicesList] = useState<SelectOption[]>([]);

  /**
   * Initializes the breadcrumb paths
   */
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbPath[]>([]);

  /**
   * Initializes the draft work order not found flag
   */
  const [draftNotFound, setDraftNotFound] = useState(false);

  /**
   * Initializes the draft work order removed flag
   */
  const [draftRemoved, setDraftRemoved] = useState(false);

  /**
   * Gets the route params
   */
  const { draftId } = useParams<DraftWorkOrderRouteParams>();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Gets work order utils
   */
  const {
    getErrorTabs,
    getWorkOrderById,
    createBreadcrumb,
    getDefaultValues,
    getWorkOrderTabs,
    checkTabForErrors,
    shouldValidateTab,
    shouldInvalidateTab,
    getDefaultTabsState,
    createWorkOrderDraftBody,
    convertDraftWorkOrderToInputs,
  } = useWorkOrderUtils();

  /**
   * Initializes the tabs state
   */
  const {
    activeTab,
    errorTabs,
    disabledTabs,
    validatedTabs,
    activateTab,
    setActiveTab,
    setErrorTabs,
    setDisabledTabs,
    setValidatedTabs,
  } = useTabs({ defaults: getDefaultTabsState });

  /**
   * Gets products utils
   */
  const { calculatePricing, getSelectOptions } = useProductUtils();

  /**
   * Gets user utils
   */
  const { getUserProducts } = useUserUtils();

  /**
   * Gets the user products
   */
  const userProducts = getUserProducts;

  /**
   * Initializes the form
   */
  const methods = useForm<FormBody>({
    defaultValues: getDefaultValues(),
    mode: "all",
    criteriaMode: "all",
  });

  /**
   * Gets the form methods
   */
  const {
    control,
    formState,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
  } = methods;

  /**
   * Gets the products state
   */
  const {
    products,
    updateRow,
    addNewProductRow,
    removeProductRow,
    updateProductRows,
  } = useProductsForm({ control, watch });

  /**
   * Handles initializing the breadcrumb
   */
  const initializeBreadcrumb = () => {
    const baseBreadcrumb = createBreadcrumb("drafts", draftWorkOrder);
    setBreadcrumbPaths(baseBreadcrumb);
  };

  /**
   * Handles deleting the draft
   */
  const deleteDraft = () => {
    setDraftRemoved(true);
    setDraftNotFound(false);
  };

  /**
   * Handles the tab change
   */
  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    validateTab(activeTab);
    activateTab(event, newValue);
  };

  /**
   * Handles the form submission
   */
  const onFormSubmit = handleSubmit(() => {
    if (userProducts.length < 1) {
      setActiveTab(2);
      return dispatchMessage({
        message: t("StillFetchingProductsPleaseTryAgain"),
        severity: "warning",
      });
    }

    if (loading) return;

    /**
     * Handles creating a work order
     */
    setLoading(true);
    updateDraft({ callback: createWorkOrder });
  });

  /**
   * Handles validating a tab
   */
  const validateTab = (index: number) => {
    const formBody = getValues();
    const shouldValidate = shouldValidateTab(index, formState, formBody);
    const shouldInvalidate = shouldInvalidateTab(index, formState, formBody);
    const hasErrors = checkTabForErrors(index, formState);

    if (shouldValidate) {
      setValidatedTabs((prevTabs) => uniq([...prevTabs, index]));
    }

    if (shouldInvalidate) {
      setValidatedTabs(validatedTabs.filter((tab) => tab !== index));
    }

    if (errorTabs.includes(index) && !hasErrors) {
      setErrorTabs(errorTabs.filter((tab) => tab !== index));
    }
  };

  /**
   * Handles initializing the draft work order related state
   */
  const initializeDraftWorkOrder = (workOrder: WorkOrder) => {
    const formBody = convertDraftWorkOrderToInputs(workOrder);
    const breadcrumb = createBreadcrumb("drafts", workOrder);

    reset(formBody);
    setErrorTabs([]);
    setDisabledTabs([]);
    setBreadcrumbPaths(breadcrumb);
    setValidatedTabs([0, 1, 2, 3]);
    setDraftWorkOrder(workOrder);
    setValue("products", formBody.products);
  };

  /**
   * Handles getting the draft work order
   */
  const getDraftWorkOrder = async () => {
    setLoading(true);

    /**
     * Defines the api call success callback
     */
    const onSuccess: GetDraftWorkOrderOnSuccess = ({ data }) => {
      setLoading(false);
      initializeDraftWorkOrder(data);
    };

    /**
     * Defines the api call error callback
     */
    const onError: RequestOnError = (error) => {
      setLoading(false);
      if (error.errorMessage.includes("No query results")) {
        setDraftRemoved(false);
        setDraftNotFound(true);
        return;
      } else {
        dispatchMessage({
          message: "Error: Requesting work order failed.",
          severity: "error",
        });
      }
    };

    await apiCalls.getDraftWorkOrder(draftId, onSuccess, onError);
  };

  /**
   * Returns the work order link
   */
  const getWorkOrderLink = (id: string | number, viewMode: string) => {
    const baseUrl = "/dashboard/workstation-settings/work-orders/edit/";

    return `${baseUrl}${id}?viewMode=${viewMode}`;
  };

  /**
   * Handles updating the draft work order
   */
  const updateDraft = async (props?: UpdateDraftProps) => {
    const formBody = getValues();

    /**
     * Creates the request body
     */
    const data = createWorkOrderDraftBody(formBody);

    if (data) {
      setDraftLoading(true);

      /**
       * Defines the api call success callback
       */
      const onSuccess: UpdateWorkOrderDraftOnSuccess = ({ data }) => {
        if (props && props.callback) {
          props.callback();
          return setDraftLoading(false);
        }

        debounce(() => {
          dispatchMessage({
            title: data.uuid,
            message: t("DraftUpdatedSuccessfully"),
            severity: "success",
            autoClose: 5000,
          });

          setDraftLoading(false);
        }, 350);
      };

      /**
       * Defines the api call error callback
       */
      const onError: RequestOnError = () => {
        setDraftLoading(false);
        dispatchMessage({
          message: "Error",
          severity: "error",
        });
      };

      await apiCalls.updateWorkOrderDraft(draftId, data, onSuccess, onError);
    }
  };

  /**
   * Handles creating a new work order
   */
  const createWorkOrder = async () => {
    /**
     * Defines the api call success callback
     */
    const onSuccess: TransformWorkOrderDraftOnSuccess = ({ data }) => {
      setLoading(false);
      dispatchMessage({
        title: data.uuid,
        message: t("WorkOrderCreated"),
        severity: "success",
        autoClose: 5000,
      });

      debounce(() => {
        history.push(getWorkOrderLink(data.id, "view"));
      }, 2000);
    };

    /**
     * Defines the api call error callback
     */
    const onError: RequestOnError = () => {
      setLoading(false);
      dispatchMessage({
        message: "Failed to convert draft to work order.",
        severity: "error",
      });
    };

    await apiCalls.transformWorkOrderDraft(draftId, onSuccess, onError);
  };

  /**
   * Gets the initial services and products
   */
  useEffect(() => {
    if (userProducts.length > 0) {
      const { services, products } = getSelectOptions(userProducts);

      setServicesList(services);
      setProductsList(products);
    }
    // eslint-disable-next-line
  }, [userProducts]);

  /**
   * Initializes the draft work order
   */
  useEffect(() => {
    if (userInitialized) {
      getDraftWorkOrder();
    }

    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Checks the tabs for errors when the active tab changes
   */
  useEffect(() => {
    setErrorTabs(getErrorTabs(formState));
    // eslint-disable-next-line
  }, [activeTab]);

  /**
   * Enabled or disables the summary tab
   */
  useEffect(() => {
    setDisabledTabs(validatedTabs.length > 2 ? [] : [3]);
    // eslint-disable-next-line
  }, [validatedTabs]);

  /**
   * Handles calculating the subtotal / discount and total based on products / services
   */
  useEffect(() => {
    if (products.length > 0) {
      const { subtotal, discount, total } = calculatePricing({
        orderProducts: products,
        carTypeId: getValues("workOrder.carTypeId"),
      });

      setValue("workOrder.discount", discount);
      setValue("workOrder.subtotal", subtotal);
      setValue("workOrder.total", total);
    }
    // eslint-disable-next-line
  }, [products]);

  /**
   * Initializes the breadcrumb path
   */
  useEffect(() => {
    initializeBreadcrumb();
    // eslint-disable-next-line
  }, [draftWorkOrder]);

  /**
   * Handles deleting the draft work order through web sockets
   */
  useEffect(() => {
    if (userInitialized) {
      listen({
        channel: "work-order",
        event: "WorkOrder",
        withAuth: true,
        callback: (payload) => {
          const { event, model, modelName } = payload;

          if (modelName === "WorkOrderDraft" && event === "DELETED") {
            if (model.id === toInteger(draftId)) {
              deleteDraft();
            }
          }
        },
      });
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    methods,
    loading,
    products,
    startDate,
    activeTab,
    errorTabs,
    disabledTabs,
    servicesList,
    productsList,
    validatedTabs,
    draftLoading,
    breadcrumbPaths,
    draftNotFound,
    draftRemoved,
    draftWorkOrder,
    updateDraft,
    updateRow,
    activateTab,
    handleTabChange,
    validateTab,
    setErrorTabs,
    setActiveTab,
    onFormSubmit,
    deleteDraft,
    setDraftLoading,
    setDisabledTabs,
    getWorkOrderTabs,
    setValidatedTabs,
    addNewProductRow,
    removeProductRow,
    updateProductRows,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
