/**
 * External imports
 */
import { isNaN, toSafeInteger, upperFirst, cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import {
  useUtils,
  useFormUtils,
  useUserUtils,
  useNavigation,
  useTimerUtils,
  useTranslation,
  useWorkOrderUtils,
  useAccountSettings,
} from "..";

/**
 * Imports types
 */
import { Tab, WorkOrder, ProductForm, BreadcrumbPath } from "../../types";
import { CreateWorkOrderBody, UpdateWorkOrderRelationsBody } from "../useApi";
import { UseTabsProps } from "../index";
import { FormState } from "react-hook-form";
import { FormBody } from "../useEditWorkOrder/Context";
import { ValidateTabProps } from "./useWorkOrderITPUtils.types";

/**
 * Imports constants
 */
import { WORK_ORDER_ITP_TABS } from "../../constants";

/**
 * Provides utility functions for manipulating work orders
 */
export const useWorkOrderITPUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the user data
   */
  const {
    convertCarToInputs,
    convertWorkOrderDataToInputs,
    convertWorkersToInputs,
    convertProductsToInputs,
  } = useWorkOrderUtils();

  /**
   * Gets the paths
   */
  const { Paths } = useNavigation();

  /**
   * Gets the user data utils
   */
  const {
    getProductById,
    getCarTypeName,
    getCarTypeByName,
    getWheelTypeByName,
    getWorkOrderTypeById,
    getDefaultWorkOrderTypeITP,
  } = useUserUtils();

  /**
   * Gets the account settings
   */
  const { ownProductDiscount } = useAccountSettings();

  /**
   * Gets form utils
   */
  const { getDefaultForm } = useFormUtils();

  /**
   * Gets timer utils
   */
  const { getTimeDifference, formatTimeUnit } = useTimerUtils();

  /**
   * Gets general utils
   */
  const { toFloat, toInteger, formatDate, removeEmpty, fromCamelToSnake } =
    useUtils();

  /**
   * Returns the work order ITP tabs
   */
  const getWorkOrderITPTabs = [
    {
      key: WORK_ORDER_ITP_TABS.ITP_FORM,
      label: t("ITPForm"),
      index: 0,
    },
    {
      key: WORK_ORDER_ITP_TABS.SUMMARY,
      label: t("Summary"),
      index: 1,
      timer: true,
    },
  ] as Tab[];

  /**
   * Checks if the provided tab index is valid
   */
  const isValidTab = (
    formBody: FormBody,
    tabIndex: number,
    hasErrors: boolean,
  ) => {
    const { car, workOrder, tyreService, workers } = formBody;

    if (hasErrors) return false;

    if (tabIndex === 0) {
      const plateNumber = car.plateNumber.replace(/\s/g, "");
      const clientName = workOrder.clientName.replace(/\s/g, "");
      return plateNumber.length > 0 && clientName.length > 0;
    }

    if (tabIndex === 1) {
      return tyreService.wheelType && workOrder.carTypeId;
    }

    if (tabIndex === 2) {
      return workers.length > 0;
    }

    if (tabIndex === 3) {
      return !hasErrors;
    }
  };

  /**
   * Returns the validated tabs
   */
  const getValidatedTabs = (formBody: FormBody) => {
    const { car, workOrder, workers } = formBody;

    /**
     * Initializes the validated tabs
     */
    const validatedTabs: number[] = [];

    const plateNumber = car.plateNumber.replace(/\s/g, "");
    const clientName = workOrder.clientName.replace(/\s/g, "");

    if (plateNumber.length > 0 && clientName.length > 0 && workers.length > 0) {
      validatedTabs.push(0);
    }

    return validatedTabs;
  };

  /**
   * Checks if the provided tab has any errors
   */
  const checkTabForErrors = (formState: FormState<FormBody>) => {
    const { errors } = formState;
    const { car, workOrder, workers, products } = errors;

    if ((car && car.plateNumber) || (workOrder && workOrder.clientName)) {
      return true;
    }

    if (workers || products) {
      return true;
    }

    return false;
  };

  /**
   * Handles validating a tab
   */
  const validateTab = (props: ValidateTabProps) => {
    const { tabIndex, formState, formBody } = props;

    if (tabIndex === 0) {
      const hasErrors = checkTabForErrors(formState);
      const isValid = isValidTab(formBody, tabIndex, hasErrors);

      return { valid: isValid, hasErrors };
    }

    return { valid: false, hasErrors: false };
  };

  /**
   * Checks if the provided tab should be validated
   */
  const shouldValidateTab = (
    tabIndex: number,
    formState: FormState<FormBody>,
    formBody: FormBody,
  ) => {
    const hasErrors = checkTabForErrors(formState);
    const isValid = isValidTab(formBody, tabIndex, hasErrors);

    return isValid && !hasErrors;
  };

  /**
   * Checks if the provided tab should be invalidated
   */
  const shouldInvalidateTab = (
    tabIndex: number,
    formState: FormState<FormBody>,
    formBody: FormBody,
  ) => {
    const hasErrors = checkTabForErrors(formState);
    const isValid = isValidTab(formBody, tabIndex, hasErrors);

    return !isValid || hasErrors;
  };

  /**
   * Returns the default tabs state
   */
  const getDefaultTabsState = {
    activeTab: 0,
    errorTabs: [],
    disabledTabs: [1],
    validatedTabs: [],
  } as UseTabsProps["defaults"];

  /**
   * Returns the error tabs
   */
  const getErrorTabs = (formState: FormState<FormBody>) => {
    /**
     * Initializes the error tabs
     */
    let errorTabs: number[] = [];

    const errors = checkTabForErrors(formState);

    if (errors) errorTabs.push(0);

    return errorTabs;
  };

  /**
   * Handles creating the breadcrumb for the work order controller
   */
  const createBreadcrumb = (mode: "edit" | "create", workOrder?: WorkOrder) => {
    /**
     * Initializes the breadcrumb
     */
    const baseBreadcrumb: BreadcrumbPath[] = [
      {
        label: t("Dashboard"),
        link: true,
        url: Paths.Dashboard,
      },
      {
        label: t("WorkOrders"),
        link: true,
        url: Paths.WorkOrders,
      },
      {
        label: t(upperFirst(mode)),
        link: false,
      },
    ];

    if (mode === "edit" && workOrder) {
      baseBreadcrumb.push({
        label: workOrder.uuid,
        link: false,
      });
    }

    return baseBreadcrumb;
  };

  /**
   * Returns the default car type
   */
  const getDefaultCarType = () => {
    const defaultCarType = getCarTypeByName("Autoturism");

    return defaultCarType ? defaultCarType.id : "";
  };

  /**
   * Returns the default wheel type
   */
  const getDefaultWheelType = () => {
    const defaultWheelType = getWheelTypeByName("Aluminiu");

    return defaultWheelType ? defaultWheelType.id : "";
  };

  /**
   * Returns the draft work order type id
   */
  const getDraftWorkOrderTypeId = (draft: FormBody, form: FormBody) => {
    return draft.workOrder.workOrderTypeId
      ? draft.workOrder.workOrderTypeId
      : form.workOrder.workOrderTypeId;
  };

  /**
   * Returns the draft car type id
   */
  const getDraftCarTypeId = (draft: FormBody, form: FormBody) => {
    return draft.workOrder.carTypeId
      ? draft.workOrder.carTypeId
      : form.workOrder.carTypeId;
  };

  /**
   * Returns the draft car type id
   */
  const getDraftWheelType = (draft: FormBody, form: FormBody) => {
    return draft.tyreService.wheelType
      ? draft.tyreService.wheelType
      : form.tyreService.wheelType;
  };

  /**
   * Returns the draft start date
   */
  const getDraftStartDate = (draft: FormBody, form: FormBody) => {
    return draft.workOrder.startDate
      ? draft.workOrder.startDate
      : form.workOrder.startDate;
  };

  /**
   * Returns the create work order ITP default values
   */
  const getDefaultValuesITP = (draft?: FormBody) => {
    /**
     * Gets the default form
     */
    const defaultForm = getDefaultForm("CREATE_WORK_ORDER") as FormBody;

    /**
     * Gets the default work order type
     */
    const defaultWorkOrderType = getDefaultWorkOrderTypeITP();

    /**
     * Clones the form and the draft
     */
    const clonedForm = cloneDeep(defaultForm);
    const clonedDraft = draft ? cloneDeep(draft) : undefined;

    /**
     * Updates the inputs
     */
    clonedForm.workOrder.startDate = new Date() as any;
    clonedForm.workOrder.workOrderTypeId = defaultWorkOrderType?.id || "";
    clonedForm.workOrder.carTypeId = getDefaultCarType();
    clonedForm.tyreService.wheelType = getDefaultWheelType();

    if (clonedDraft) {
      const workOrderTypeId = getDraftWorkOrderTypeId(clonedDraft, clonedForm);
      const carTypeId = getDraftCarTypeId(clonedDraft, clonedForm);
      const wheelType = getDraftWheelType(clonedDraft, clonedForm);
      const startDate = getDraftStartDate(clonedDraft, clonedForm);

      clonedDraft.workOrder.workOrderTypeId = workOrderTypeId;
      clonedDraft.workOrder.carTypeId = carTypeId;
      clonedDraft.tyreService.wheelType = wheelType;
      clonedDraft.workOrder.startDate = startDate;

      return clonedDraft;
    }

    return clonedForm;
  };

  /**
   * Gets the work order duration
   */
  const getWorkOrderDuration = (startDate: Date) => {
    const { hours, minutes, seconds } = getTimeDifference(
      startDate,
      new Date(),
    );

    /**
     * Formats the time units
     */
    const h = formatTimeUnit(hours);
    const m = formatTimeUnit(minutes);
    const s = formatTimeUnit(seconds);

    return `${h}:${m}:${s}`;
  };

  const buildCustomProduct = (product: ProductForm) => {
    return {
      type: product.type,
      quantity: toInteger(product.quantity),
      price: toFloat(product.price),
      total: toFloat(product.total),
      productId: "",
      name: product!.customProductName,
      um: "BUC",
      isService: false,
      isExpendable: false,
      isCountPrice: false,
      isTyre: product.isTyre,
    };
  };

  /**
   * Returns the form products formatted for the work order request
   */
  const getProducts = (products: ProductForm[]) => {
    const result = products.map((product) => {
      const userProduct = getProductById(product.productId);

      const baseProduct: unknown = userProduct
        ? {
            ...userProduct!,
            organizationProductId: userProduct!.id,
            quantity: toInteger(product.quantity),
            price: toFloat(product.price),
            total: toFloat(product.total),
          }
        : buildCustomProduct(product);

      return baseProduct;
    });

    return (result as any[]).filter((product) => {
      if (!product.name) return false;
      return true;
    }) as ProductForm[];
  };

  /**
   * Gets the account discount value
   */
  const getAccountDiscountValue = () => {
    if (ownProductDiscount) {
      if (isNaN(ownProductDiscount)) return 0;

      return ownProductDiscount;
    }
  };

  /**
   * Calculates the work order discount
   */
  const calculateWorkOrderDiscount = (formBody: FormBody) => {
    const { workOrder } = formBody;
    const { discount, metaData } = workOrder;
    const { ownProduct } = metaData || {};

    const accountDiscount = getAccountDiscountValue();

    if (ownProduct && accountDiscount) {
      return toSafeInteger(discount) + accountDiscount;
    }

    return toSafeInteger(discount);
  };

  /**
   * Handles removing the products from the form body if empty
   */
  const sanitizeProducts = (formBody: FormBody) => {
    if (formBody.products.length < 1) {
      // @ts-ignore
      delete formBody.products;

      formBody.workOrder.total = 0;
      formBody.workOrder.discount = 0;
      formBody.workOrder.subtotal = 0;
    }
  };

  /**
   * Sanitize work order body
   */
  const sanitizeWorkOrderBody = (clone: any) => {
    const { workOrder } = clone;
    const { workOrderTypeId } = workOrder;

    /**
     * Gets the work order type
     */
    const workOrderType = getWorkOrderTypeById(workOrderTypeId);

    if (workOrderType) {
      if (workOrderType.name === "Service Auto") {
        /**
         * Removes unused fields
         */
        delete clone.tyreService;
        delete clone.tyreHotel;
      }

      if (workOrderType.shortName === "VLC") {
        /**
         * Removes unused fields
         */
        delete clone.carService;
      }
    }
  };

  /**
   * Returns the work order if found in the provided list
   */
  const getWorkOrderById = (
    workOrderId: string | number,
    list: WorkOrder[],
  ) => {
    const id =
      typeof workOrderId === "string" ? toInteger(workOrderId) : workOrderId;

    return list.find((workOrder) => workOrder.id === id);
  };

  /**
   * Returns the work order body
   */
  const getWorkOrderBody = (formBody: FormBody) => {
    const clone: any = cloneDeep(formBody);
    const { workOrder } = formBody;
    const { carTypeId } = workOrder;

    clone.car.type = getCarTypeName(carTypeId);

    /**
     * Remove unused data
     */
    delete clone.carService;
    delete clone.tyreService;
    delete clone.tyreHotelEnabled;
    delete clone.tyreHotel;

    // @ts-ignore
    delete clone.products;

    /**
     * Transforms to snake case
     */
    return fromCamelToSnake(clone) as UpdateWorkOrderRelationsBody;
  };

  /**
   * Converts the form inputs to a valid work order request body
   */
  const createWorkOrderBodyITP = (formBody: FormBody) => {
    const clone = cloneDeep(formBody);

    const { workOrder, products } = formBody;
    const { startDate, carTypeId } = workOrder;

    clone.workOrder.endDate = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
    clone.workOrder.startDate = formatDate(
      new Date(startDate),
      "yyyy-MM-dd HH:mm:ss",
    );
    clone.workOrder.duration = getWorkOrderDuration(new Date(startDate));
    clone.car.type = getCarTypeName(carTypeId);

    clone.products = getProducts(products);
    clone.workOrder.discount = calculateWorkOrderDiscount(clone);
    clone.workOrder.total =
      toFloat(clone.workOrder.subtotal) - clone.workOrder.discount;

    clone.workOrder.itp = true;

    /**
     * Sanitizes the products
     */
    sanitizeProducts(clone);

    /**
     * Sanitizes the work order body
     */
    sanitizeWorkOrderBody(clone);

    /**
     * Removes empty values
     */
    const clean = removeEmpty(clone);

    /**
     * Removes unused fields
     */
    // @ts-ignore
    delete clone.carService;

    /**
     * Transforms to snake case
     */
    return fromCamelToSnake(clean) as CreateWorkOrderBody;
  };

  /**
   * Handles updating the breadcrumb's view mode
   */
  const updateBreadcrumbViewMode = (
    viewMode: "edit" | "view",
    breadcrumb: BreadcrumbPath[],
    index?: number,
  ) => {
    const cloneBreadcrumb = cloneDeep(breadcrumb);
    const viewModeIndex = cloneBreadcrumb.length - (index || 2);

    cloneBreadcrumb[viewModeIndex] = {
      ...cloneBreadcrumb[viewModeIndex],
      label: t(upperFirst(viewMode)),
    };

    return cloneBreadcrumb;
  };

  /**
   * Converts the work order itp to inputs
   */
  const convertWorkOrderITPToInputs = (data: WorkOrder) => {
    const defaultValues = getDefaultValuesITP();
    const { workOrder, car } = defaultValues;

    convertCarToInputs(car, data);
    convertWorkOrderDataToInputs(workOrder, data);
    convertWorkersToInputs(defaultValues, data);
    convertProductsToInputs(defaultValues, data);

    defaultValues.car = car;
    defaultValues.workOrder = workOrder;

    return defaultValues;
  };

  return {
    isValidTab,
    validateTab,
    getErrorTabs,
    getWorkOrderById,
    getWorkOrderBody,
    getCarTypeByName,
    getValidatedTabs,
    createBreadcrumb,
    getDefaultCarType,
    checkTabForErrors,
    shouldValidateTab,
    getWheelTypeByName,
    shouldInvalidateTab,
    getDefaultTabsState,
    createWorkOrderBodyITP,
    getWorkOrderITPTabs,
    getDefaultValuesITP,
    updateBreadcrumbViewMode,
    convertWorkOrderITPToInputs,
  };
};
