/**
 * External imports
 */
import { cloneDeep, sortBy, uniqBy } from "lodash";

/**
 * Imports hooks
 */
import { useAccountSettings, useTranslation, useUserUtils, useUtils } from "..";

/**
 * Imports constants
 */
import { DICT, SPECIAL_SERVICES } from "../../constants";

/**
 * Imports types
 */
import {
  CalculatePricingProps,
  SyncProductsProps,
  UpdatePricingProps,
  InitializeSelectOptionsProps,
  AddPriceToSelectOptionsProps,
} from "./useProductUtils.types";
import { FormBody, WorkOrderProduct } from "../useCreateWorkOrder/Context";
import {
  Product,
  SelectOption,
  Conditional,
  WorkOrderProduct as EditWorkOrderProduct,
} from "../../types";

/**
 * Provides utility functions for products
 */
export const useProductUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets user utility functions
   */
  const { getCarTypeById, getProductById, getNoOptions } = useUserUtils();

  /**
   * Gets the user state
   */
  const { discountOnNewProduct } = useAccountSettings();

  /**
   * Gets general utils
   */
  const { toFloat, toInteger } = useUtils();

  /**
   * Returns the products of type 'service'
   */
  const getServices = (products: WorkOrderProduct[]) => {
    return products.filter((product) => product.type === "service");
  };

  /**
   * Returns the products of type 'product'
   */
  const getProducts = (products: WorkOrderProduct[]) => {
    return products.filter((product) => product.type === "product");
  };

  /**
   * Performs comparisons based on condition
   */
  const checkCondition = (a: number, b: number, condition: Conditional) => {
    if (condition === "=") {
      return a === b;
    } else if (condition === ">=") {
      return a >= b;
    } else if (condition === "<=") {
      return a <= b;
    } else if (condition === ">") {
      return a > b;
    } else if (condition === "<") {
      return a < b;
    }
  };

  /**
   * Handles applying pricing conditions
   */
  const applyPriceConditions = (
    product: Product,
    tyreService: FormBody["tyreService"],
  ) => {
    const { priceConditions } = product;

    if (!priceConditions || priceConditions.length < 1) {
      return {
        conditional: false,
        price: toFloat(product.price),
      };
    }

    let conditionMet = false;
    let price = toFloat(product.price);

    /**
     * Defines the key fields
     */
    const keyFields: { [key: string]: any } = {
      tyre_rim: "tyreRim",
      tyre_width: "tyreWidth",
      tyre_height: "tyreHeight",
    };

    priceConditions.forEach((condition) => {
      if (keyFields[condition.field]) {
        const key = keyFields[condition.field];
        const value = (tyreService as any)[key];
        const operand = condition.condition;

        const match = checkCondition(
          toFloat(value),
          toFloat(condition.value),
          operand,
        );

        if (match) {
          conditionMet = true;
          price = toFloat(condition.newPrice);
        }
      }
    });

    return {
      conditional: conditionMet,
      price,
    };
  };

  /**
   * Handles formatting the options
   */
  const formatOptions = (options: any[]) => {
    return uniqBy(sortBy(options.concat(getNoOptions), "label.0"), "label");
  };

  /**
   * Handles applying price conditions to the services
   */
  const addPriceToSelectOptions = (props: AddPriceToSelectOptionsProps) => {
    const { services, products, tyreService } = props;

    /**
     * Clones the lists
     */
    const servicesList = cloneDeep(services);
    const productsList = cloneDeep(products);

    for (let i = 0; i < servicesList.length; i++) {
      const service = getProductById(servicesList[i].value);

      if (service) {
        const { price } = applyPriceConditions(service, tyreService);
        servicesList[i].label = `${service.name} (${price} RON)`;
      }
    }

    for (let i = 0; i < productsList.length; i++) {
      const product = getProductById(productsList[i].value);

      if (product) {
        const { price } = applyPriceConditions(product, tyreService);
        productsList[i].label = `${product.name} (${price} RON)`;
      }
    }

    return {
      services: formatOptions(servicesList),
      products: formatOptions(productsList),
    };
  };

  /**
   * Handles formatting the product name
   */
  const formatProductName = (name: string) => {
    /**
     * Defines the whitespace regex
     */
    const excessWhitespace = /^\s+|\s+$|\s+(?=\s)/g;

    if (!name) return "";

    return name
      .toString()
      .toLowerCase()
      .replace(excessWhitespace, "")
      .replace(/[^\w ]/g, function (char: any) {
        return (DICT as any)[char] || char;
      });
  };

  /**
   * Handles calculating the subtotal
   */
  const calculateSubtotal = (
    orderProducts: WorkOrderProduct[] | EditWorkOrderProduct[],
  ) => {
    let total = 0;

    orderProducts.forEach((product) => {
      total += toFloat(product.total);
    });

    return total;
  };

  /**
   * Checks if the provided service is matching the criteria for special discounts
   * Used in Add / Edit / Delete work order products
   */
  const isMatchingDiscountCriteria = (
    service: EditWorkOrderProduct,
    carTypeName: string,
  ) => {
    let foundMatch = false;

    const name = formatProductName(service.name);

    if (name.includes("echilibra") && carTypeName === "Camion") {
      return false;
    }

    if (!discountOnNewProduct) return false;

    SPECIAL_SERVICES.forEach((key) => {
      const productName = formatProductName(key);

      if (name.includes(productName)) {
        foundMatch = true;
      }
    });

    return foundMatch;
  };

  /**
   * Checks if the provided service is matching the criteria for special discounts
   */
  const isServiceMatchingCriteria = (
    service: WorkOrderProduct,
    carTypeName: string,
  ) => {
    const product = getProductById(service.productId);

    if (!product) return false;

    let foundMatch = false;

    const name = formatProductName(product.name);

    if (name.includes("echilibra") && carTypeName === "Camion") {
      return false;
    }

    SPECIAL_SERVICES.forEach((key) => {
      const productName = formatProductName(key);

      if (name.includes(productName)) {
        foundMatch = true;
      }
    });

    return foundMatch;
  };

  /**
   * Handles calculating the special discount
   */
  const getSpecialDiscount = (
    orderProducts: WorkOrderProduct[],
    carTypeId: number,
  ) => {
    if (orderProducts.length < 1) return 0;

    /**
     * Gets the car type
     */
    const carType = getCarTypeById(carTypeId);

    /**
     * Gets the services
     */
    const services = getServices(orderProducts);

    /**
     * Gets the products
     */
    const products = getProducts(orderProducts);

    /**
     * Initializes the quantity
     */
    let productsQuantity = 0;

    /**
     * Initializes the discount
     */
    let discount = 0;

    /**
     * Increments the quantity
     */
    products.forEach((product) => {
      if (product.quantity) {
        productsQuantity += toInteger(product.quantity);
      }
    });

    /**
     * Increments the discount based on eligible services
     */
    services.forEach((service) => {
      const carTypeName = carType ? carType.name : "";
      const isMatching = isServiceMatchingCriteria(service, carTypeName);

      if (isMatching) {
        if (toInteger(service.quantity) <= productsQuantity) {
          discount += toInteger(service.quantity) * toFloat(service.price);
        }

        if (toInteger(service.quantity) > productsQuantity) {
          discount += productsQuantity * toFloat(service.price);
        }
      }
    });

    return discount ? discount : 0;
  };

  /**
   * Handles updating the pricing of products based on dynamic discounts and work order form data
   * Used only in Add / Edit / Delete products
   */
  const updatePricing = (props: UpdatePricingProps) => {
    const { orderProducts, currentDiscount, carTypeId } = props;

    if (orderProducts.length < 1)
      return { subtotal: 0, discount: toFloat(currentDiscount), total: 0 };

    /**
     * Gets the car type
     */
    const carType = getCarTypeById(carTypeId);

    /**
     * Gets the services
     */
    const services = orderProducts.filter((product) => product.isService);

    /**
     * Gets the products
     */
    const products = orderProducts.filter((product) => !product.isService);

    /**
     * Initializes the quantity
     */
    let productsQuantity = 0;

    /**
     * Initializes the discount
     */
    let discount = 0;

    /**
     * Increments the quantity
     */
    products.forEach((product) => {
      if (product.quantity) {
        productsQuantity += toInteger(product.quantity);
      }
    });

    /**
     * Increments the discount based on eligible services
     */
    services.forEach((service) => {
      const carTypeName = carType ? carType.name : "";
      const isMatching = isMatchingDiscountCriteria(service, carTypeName);

      if (isMatching) {
        if (service.quantity <= productsQuantity) {
          discount += toInteger(service.quantity) * toFloat(service.price);
        }

        if (service.quantity > productsQuantity) {
          discount += productsQuantity * toFloat(service.price);
        }
      }
    });

    /**
     * Calculates the subtotal
     */
    const subtotal = calculateSubtotal(orderProducts);

    /**
     * Defines the old discount
     */
    const oldDiscount = toFloat(currentDiscount);

    /**
     * Defines the final discount
     */
    const finalDiscount = oldDiscount > discount ? oldDiscount : discount;

    /**
     * Calculates the total
     */
    const total = subtotal - finalDiscount;

    return {
      subtotal,
      discount: finalDiscount > subtotal ? subtotal : finalDiscount,
      total: total < 0 ? 0 : total,
    };
  };

  /**
   * Handles calculating the pricing of products based on dynamic discounts and work order form data
   */
  const calculatePricing = (props: CalculatePricingProps) => {
    const { orderProducts, carTypeId } = props;

    if (orderProducts.length < 1) return { subtotal: 0, discount: 0, total: 0 };

    /**
     * Gets the discount
     */
    const discount = getSpecialDiscount(orderProducts, toInteger(carTypeId));

    /**
     * Calculates the subtotal
     */
    const subtotal = calculateSubtotal(orderProducts);

    /**
     * Calculates the total
     */
    const total = subtotal - discount;

    return { subtotal, discount, total };
  };

  /**
   * Initializes the select options
   */
  const initializeSelectOptions = (props: InitializeSelectOptionsProps) => {
    const { userProducts, workOrderTypeId, tyreService, carTypeId } = props;

    /**
     * Initializes the services
     */
    const services: SelectOption[] = [];

    /**
     * Initializes the products
     */
    const products: SelectOption[] = [];

    /**
     * Initializes the service ids
     */
    const serviceIds: number[] = [];

    /**
     * Initializes the product ids
     */
    const productIds: number[] = [];

    userProducts.forEach((listItem) => {
      /**
       * Defines the option
       */
      const option: SelectOption = {
        label: listItem.name,
        value: listItem.id,
      };

      const matchingCarType =
        listItem.carTypeId === null || listItem.carTypeId === carTypeId;

      const matchingWorkOrderType =
        listItem.workOrderTypeId === null ||
        listItem.workOrderTypeId === workOrderTypeId;

      if (matchingCarType && matchingWorkOrderType) {
        if (listItem.isService) {
          serviceIds.push(listItem.id);
          services.push(option);
        } else {
          productIds.push(listItem.id);
          products.push(option);
        }
      }
    });

    const selectOptions = addPriceToSelectOptions({
      services,
      products,
      tyreService,
    });

    return {
      servicesList: selectOptions.services,
      productsList: selectOptions.products,
      serviceIds,
      productIds,
    };
  };

  /**
   * Returns the services and products list
   */
  const getSelectOptions = (userProducts: Product[]) => {
    /**
     * Initializes the services
     */
    const services: SelectOption[] = [];

    /**
     * Initializes the products
     */
    const products: SelectOption[] = [];

    userProducts.forEach((listItem) => {
      /**
       * Defines the option
       */
      const option: SelectOption = {
        label: `${listItem.name} (${listItem.price} RON)`,
        value: listItem.id,
      };

      listItem.isService ? services.push(option) : products.push(option);
    });

    return {
      services: services,
      products: products,
    };
  };

  /**
   * Handles syncing the products
   */
  const syncProducts = (props: SyncProductsProps) => {
    const { products, serviceIds, productIds } = props;

    /**
     * Clones the products
     */
    const formProducts = cloneDeep(products);

    for (let i = 0; i < formProducts.length; i++) {
      const { type, productId, isCustomProduct } = formProducts[i];

      /**
       * Checks if the service is missing
       */
      const serviceMissing =
        type === "service" && !serviceIds.includes(toInteger(productId));

      /**
       * Checks if the product is missing
       */
      const productMissing =
        type === "product" && !productIds.includes(toInteger(productId));

      /**
       * Reset missing product / service
       */
      if ((productMissing || serviceMissing) && !isCustomProduct) {
        formProducts[i].productId = "";
        formProducts[i].quantity = "";
        formProducts[i].price = "";
        formProducts[i].total = "";
      }
    }

    return formProducts;
  };

  /**
   * Returns the product type
   */
  const getProductType = (product: Product) => {
    if (product.isService) return "service";
    return "product";
  };

  /**
   * Returns the work order product types options
   */
  const getProductTypeOptions: SelectOption[] = [
    { label: t("Service"), value: "service" },
    { label: t("Product"), value: "product" },
  ];

  return {
    getProductType,
    getProductTypeOptions,
    getSelectOptions,
    initializeSelectOptions,
    calculatePricing,
    applyPriceConditions,
    syncProducts,
    calculateSubtotal,
    updatePricing,
  };
};
