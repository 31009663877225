import { useState, useEffect, useCallback } from "react";

/**
 * External imports
 */
import { getWeek, addDays, format, startOfISOWeek } from "date-fns";
import { uniqueId, range } from "lodash";

/**
 * Imports hooks
 */
import { useApi, useSelector, useActions, useParams } from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import {
  GuestCarName,
  GuestCarModel,
  GuestAppointmentGroup,
  GuestAppointment,
  GuestOrganization,
  GenericObject,
} from "../../types";
import {
  RequestOnError,
  GetGuestOrganizationOnSuccess,
  GetGuestGeneralInfoOnSuccess,
  GetGuestOrganizationsOnSuccess,
  GetGuestOrganizationAppointmentsParams,
  GetGuestOrganizationAppointmentsOnSuccess,
} from "../useApi";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";

/**
 * Imports constants
 */
import { DEFAULT_CALENDAR_ACTIVE_VIEW } from "../../constants";

let today = new Date();

/**
 * Handles the Sunday edge case
 */
if (today.getDay() === 0) {
  today.setDate(today.getDate() + 1);
}

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const PublicAppointmentsProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  const { slug } = useParams<{ slug?: string }>();
  const { isMobile } = useSelector((state) => state.device);
  const [excludedDays, setExcludedDays] = useState<number[]>([0]);

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the not found org flag
   */
  const [notFoundOrg, setNotFoundOrg] = useState(false);

  /**
   * Initializes the car names
   */
  const [carNames, setCarNames] = useState<GuestCarName[]>([]);

  /**
   * Initializes the car models
   */
  const [carModels, setCarModels] = useState<GuestCarModel[]>([]);

  /**
   * Initializes the first request flag
   */
  const [firstRequest, setFirstRequest] = useState(true);

  /**
   * Initializes the active group id
   */
  const [activeGroupId, setActiveGroupId] = useState<number>();

  /**
   * Initializes the current date
   */
  const [currentDate, setCurrentDate] = useState(today);

  /**
   * Initializes the week number
   */
  const [weekNumber, setWeekNumber] = useState<number>(getWeek(today));

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState<GuestOrganization[]>([]);

  /**
   * Initializes the appointments
   */
  const [appointments, setAppointments] = useState<GuestAppointment[]>([]);

  /**
   * Initializes the calendar data
   */
  const [calendarData, setCalendarData] = useState<AppointmentModel[]>([]);

  /**
   * Initializes the calendar active view
   */
  const [calendarActiveView, setCalendarActiveView] = useState(
    DEFAULT_CALENDAR_ACTIVE_VIEW,
  );

  /**
   * Initializes the active organization
   */
  const [activeOrg, setActiveOrg] = useState<GuestOrganization>();

  /**
   * Initializes the calendar start time
   */
  const [calendarStartTime, setCalendarStartTime] = useState("08:00");

  /**
   * Initializes the calendar end time
   */
  const [calendarEndTime, setCalendarEndTime] = useState("18:00");

  const [calendarBreakInterval, setCalendarBreakInterval] = useState("");

  /**
   * Initializes the group options
   */
  const [groupOptions, setGroupOptions] = useState<GuestAppointmentGroup[]>([]);

  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: false, guest: true });

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = (error) => {
    setLoading(false);
    dispatchMessage({
      message: "Failed to fetch organizations.",
      severity: "error",
      autoClose: 10000,
    });
  };

  const changeDate = (calendarDate: Date, todayDate?: boolean) => {
    const today = new Date();
    const thisWeek = getWeek(today);
    const calendarWeek = getWeek(calendarDate);

    if (calendarWeek < thisWeek) return;
    if (calendarWeek > thisWeek + 4) return;

    /**
     * Handles Sunday edge case
     */
    if (calendarDate.getDay() === 0) {
      if (calendarWeek > weekNumber || todayDate) {
        const nextDay = new Date(calendarDate);
        nextDay.setDate(nextDay.getDate() + 1);

        const weekNumber = getWeek(nextDay);

        setCurrentDate(nextDay);
        setWeekNumber(weekNumber);

        if (activeOrg) {
          requestOrganizationAppointments(weekNumber, activeOrg.id);
        }

        return;
      }

      if (calendarWeek <= weekNumber) {
        const previousDay = new Date(calendarDate);
        previousDay.setDate(previousDay.getDate() - 1);

        const weekNumber = getWeek(previousDay);

        setCurrentDate(previousDay);
        setWeekNumber(weekNumber);
        if (activeOrg) {
          requestOrganizationAppointments(weekNumber, activeOrg.id);
        }

        return;
      }
    }

    if (weekNumber && activeOrg) {
      if (calendarWeek !== weekNumber) {
        const weekNumber = getWeek(calendarDate);
        requestOrganizationAppointments(weekNumber, activeOrg.id);
      }
    }

    setCurrentDate(calendarDate);
    setWeekNumber(calendarWeek);
  };

  /**
   * Handles requesting the organizations
   */
  const requestGuestOrganizations = async () => {
    setLoading(true);

    /**
     * Handles the on success callback
     */
    const onSuccess: GetGuestOrganizationsOnSuccess = ({ data }) => {
      setOrganizations(data);
      setActiveOrg(data[0]);
    };

    if (slug) {
      const onSuccess: GetGuestOrganizationOnSuccess = ({ data }) => {
        if (!data) {
          setNotFoundOrg(true);
          setLoading(false);
          return;
        }
        setOrganizations([data]);
        setActiveOrg(data);
      };

      await apiCalls.getGuestOrganization(slug, onSuccess, onRequestError);
      return;
    }

    await apiCalls.getGuestOrganizations(onSuccess, onRequestError);
  };

  const addBreaksToAppointments = (
    appointments: GuestAppointment[],
    breakInterval: string,
    weekNumber: number,
  ): GuestAppointment[] => {
    if (!breakInterval) return appointments;

    // Only proceed if breakInterval is in "HH:mm - HH:mm" format
    if (typeof breakInterval === "string" && breakInterval.includes("-")) {
      const startTime = breakInterval.split("-")[0].trim();
      const endTime = breakInterval.split("-")[1].trim();

      // Calculate the duration in minutes
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);
      const duration =
        endHour * 60 + endMinute - (startHour * 60 + startMinute);

      // Get the current year dynamically
      const currentYear = new Date().getFullYear();

      // Get the Monday of the specified ISO week number for the current year
      const firstDayOfWeek = startOfISOWeek(
        new Date(currentYear, 0, 1 + (weekNumber - 1) * 7),
      );

      // Create break appointments from Monday to Saturday
      const breakAppointments = range(0, 6).map((dayIndex) => {
        const currentDay = addDays(firstDayOfWeek, dayIndex);
        const formattedDate = format(currentDay, "yyyy-MM-dd");

        // Construct the 'from' and 'to' times
        const from = new Date(`${formattedDate}T${startTime}:00`);
        const to = new Date(`${formattedDate}T${endTime}:00`);

        return {
          appointmentGroupId: -1, // Breaks will have appointmentGroupId of -1
          duration,
          from: from.toISOString(),
          to: to.toISOString(),
          order: dayIndex + 1,
          isBreak: true,
        };
      });

      // Return the original appointments combined with the newly created break appointments
      return [...appointments, ...breakAppointments];
    }

    return appointments;
  };

  /**
   * Handles formatting the guest appointments
   */
  const formatGuestAppointments = (appointments: GuestAppointment[]) => {
    return appointments.map((appointment) => ({
      isBreak: appointment.isBreak,
      startDate: new Date(appointment.from),
      endDate: new Date(appointment.to),
      id: uniqueId(),
    }));
  };

  /**
   * Updates the calendar data
   * Orgs with no public settings or without grouping
   */
  const updateCalendarDataStandard = (data: GuestAppointment[]) => {
    setActiveGroupId(undefined);
    setGroupOptions([]);

    const calendarData = formatGuestAppointments(data);

    setCalendarData(calendarData);
  };

  const handleExcludedDays = (
    weekNumber: number,
    publicSettings: GenericObject,
  ) => {
    const { exclude, excludeSaturdays } = publicSettings;

    if (!exclude || exclude.length < 1) {
      setExcludedDays([0]);
      return;
    }

    const result: any = {};

    exclude.forEach((excluded: number) => {
      const date = new Date(excluded);
      const year = date.getFullYear();
      const week = getWeek(date);
      const day = date.getDay();

      if (!result[year]) {
        result[year] = {};
      }

      if (!result[year][week]) {
        result[year][week] = [];
      }

      result[year][week].push(day);
    });

    const today = new Date();
    const todayYear = today.getFullYear();

    if (result[todayYear] && result[todayYear][weekNumber]) {
      setExcludedDays([0].concat(result[todayYear][weekNumber]));
    } else {
      setExcludedDays([0]);
    }

    if (excludeSaturdays) {
      setExcludedDays((prevState) => [...prevState, 6]);
    }
  };

  const sortAppointmentsByTime = (appointments: GuestAppointment[]) => {
    return appointments.sort(
      (a, b) => new Date(a.from).getTime() - new Date(b.from).getTime(),
    );
  };

  const combineOverlappingAppointments = (appointments: GuestAppointment[]) => {
    const sortedAppointments = sortAppointmentsByTime(appointments);
    const merged = [];

    for (let appointment of sortedAppointments) {
      const startDate = new Date(appointment.from);
      const endDate = new Date(appointment.to);

      const prevMerged = merged[merged.length - 1];

      const prevMergedEndDate = new Date(prevMerged?.to);
      const prevMergedStartDate = new Date(prevMerged?.from);

      if (merged.length > 0 && startDate <= prevMergedEndDate) {
        prevMerged.to = new Date(
          Math.max(prevMergedEndDate.getTime(), endDate.getTime()),
        ).toISOString();

        const durationStart = prevMergedStartDate.getTime();
        const durationEnd = prevMergedEndDate.getTime();

        prevMerged.duration = Math.round((durationEnd - durationStart) / 60000);
      } else {
        merged.push(appointment);
      }
    }

    return merged;
  };

  const findOrganization = useCallback(
    (id: string | number) => {
      return organizations.find((organization) => organization.id === id);
    },
    [organizations],
  );

  const configureUsingPublicSettings = (
    organization: GuestOrganization,
    appointments: GuestAppointment[],
    weekNumber: number,
  ) => {
    const publicSettings = organization.publicSettings!;
    const { start, end, withGroup, groupIds, successMessage, breakInterval } =
      publicSettings;

    handleExcludedDays(weekNumber, publicSettings);

    if (breakInterval) setCalendarBreakInterval(breakInterval);
    if (successMessage) setSuccessMessage(successMessage);

    if (start) setCalendarStartTime(start);
    if (end) setCalendarEndTime(end);

    if (withGroup) {
      let existingGroupId =
        activeOrg?.id === organization.id && !!activeGroupId;

      if (!existingGroupId) {
        if (groupIds.length === 0) {
          setActiveGroupId(organization.appointmentGroups[0].id);
        } else {
          const filteredGroups = organization.appointmentGroups.filter(
            (group) => groupIds.includes(group.id),
          );

          setActiveGroupId(filteredGroups[0].id);
        }
      }

      if (groupIds.length === 0) {
        setGroupOptions(organization.appointmentGroups);
      } else {
        const filteredGroups = organization.appointmentGroups.filter((group) =>
          groupIds.includes(group.id),
        );

        setGroupOptions(filteredGroups);
      }

      /**
       * Filters the appointments by the first group id
       */
      const filteredAppointments = appointments.filter((appointment) => {
        const groupId = existingGroupId
          ? activeGroupId
          : groupIds.length === 0
          ? organization.appointmentGroups[0].id
          : organization.appointmentGroups.filter((group) =>
              groupIds.includes(group.id),
            )[0]?.id;

        return appointment.appointmentGroupId === groupId;
      });

      const appointmentsWithBreaks = addBreaksToAppointments(
        filteredAppointments,
        publicSettings.breakInterval,
        weekNumber,
      );

      /**
       * Maps the appointments to the calendar data
       */
      const calendarData = formatGuestAppointments(appointmentsWithBreaks);

      setCalendarData(calendarData);
    } else {
      const appointmentsWithBreaks = addBreaksToAppointments(
        appointments,
        publicSettings.breakInterval,
        weekNumber,
      );

      updateCalendarDataStandard(appointmentsWithBreaks);
    }
  };

  /**
   * Handles requesting the organization appointments
   */
  const requestOrganizationAppointments = async (
    weekNumber: number,
    organizationId: string | number,
  ) => {
    setLoading(true);

    /**
     * Defines the request params
     */
    const params: GetGuestOrganizationAppointmentsParams = {
      year: new Date().getFullYear(),
      weekNumber,
      organizationId: organizationId.toString(),
    };

    /**
     * Handles the on success callback
     */
    const onSuccess: GetGuestOrganizationAppointmentsOnSuccess = ({
      data: _data,
    }) => {
      const data = combineOverlappingAppointments(_data);
      const foundOrg = findOrganization(organizationId);

      if (foundOrg && foundOrg.publicSettings) {
        configureUsingPublicSettings(foundOrg, data, weekNumber);
      } else {
        updateCalendarDataStandard(data);
      }

      setLoading(false);
      setAppointments(data);
    };

    await apiCalls.getGuestOrganizationAppointments(
      params,
      onSuccess,
      onRequestError,
    );
  };

  /**
   * Handles requesting the general info
   */
  const requestGeneralInfo = async () => {
    /**
     * Handles the on success callback
     */
    const onSuccess: GetGuestGeneralInfoOnSuccess = ({ data }) => {
      setCarModels(data.carModels);
      setCarNames(data.carNames);
    };

    await apiCalls.getGuestGeneralInfo(onSuccess, onRequestError);
  };

  /**
   * Handles requesting the organizations
   */
  useEffect(() => {
    requestGuestOrganizations();
    requestGeneralInfo();
  }, []);

  /**
   * Handles requesting the organization appointments
   */
  useEffect(() => {
    if (activeOrg && firstRequest) {
      setFirstRequest(false);
      requestOrganizationAppointments(getWeek(currentDate), activeOrg.id);
    }
    // eslint-disable-next-line
  }, [activeOrg, firstRequest]);

  /**
   * Updates the view on mobile
   */
  useEffect(() => {
    setCalendarActiveView(isMobile ? "Day" : "Work Week");
    // eslint-disable-next-line
  }, [isMobile]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    currentDate,
    calendarData,
    organizations,
    activeOrg,
    calendarActiveView,
    activeGroupId,
    appointments,
    calendarStartTime,
    calendarEndTime,
    groupOptions,
    carModels,
    carNames,
    slug,
    notFoundOrg,
    excludedDays,
    successMessage,
    weekNumber,
    calendarBreakInterval,
    changeDate,
    setOrganizations,
    setActiveOrg,
    setActiveGroupId,
    setCalendarData,
    addBreaksToAppointments,
    requestOrganizationAppointments,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
